import React from "react";
import Chunk from "./Chunk";

const ThreeColumnContent = props => {
  return (
    <>
      {props.columns !== null &&
        <div className="columns is-variable is-8 has-text-centered">
          <div className="column is-4">
            {props.columns.columnOne !== null &&
              <Chunk
                chunk={props.columns.columnOne.chunk}
                langKey={props.langKey}
                reusable={props.reusable}
              />
            }
          </div>

          <div className="column is-4">
            {props.columns.columnTwo !== null &&
              <Chunk
                chunk={props.columns.columnTwo.chunk}
                langKey={props.langKey}
                reusable={props.reusable}
              />
            }
          </div>

          <div className="column is-4">
            {props.columns.columnThree !== null &&
              <Chunk
                chunk={props.columns.columnThree.chunk}
                langKey={props.langKey}
                reusable={props.reusable}
              />
            }
          </div>
        </div>
      }
    </>
  );
};

export default ThreeColumnContent