import React from "react";
import { Helmet } from "react-helmet";
import "../../../src/components/all.sass";
import { useSiteMetadata } from "../../../src/hooks/use-site-metadata";

import Footer from "../main/Footer";
import Navbar from "../main/Navbar";
import SEO from "../SEO";

const TemplateWrapper = ({ langKey, slug, children }) => {
  const { title, description, logo } = useSiteMetadata();
  return (
    <div>
      <SEO title={title} description={description} />
      <div style={{
        position: "relative",
        minHeight: "100vh",
      }}>
        <Navbar
          langKey={langKey}
          slug={slug}
          logo={logo}
        />
        
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default TemplateWrapper;

