import React, { useState } from "react";
import { Link } from 'gatsby'
import { startCase } from 'lodash'
import { useNavbarItems } from '../../../src/hooks/use-navbar-items'
import { useFeaturedTags } from '../../../src/hooks/use-featured-tags'
import { useLanguageList } from '../../../src/hooks/use-language-list'
import langSelector from '../../../src/img/changeLanguage.sprite.svg'

function Navbar(props) {

  const [navBarActiveClass, setNavBarActiveClass] = useState("");
  const [dropdownInfoActiveClass, setDropdownInfoActiveClass] = useState("");
  const [dropdownSectionsActiveClass, setDropdownSectionsActiveClass] = useState("");
  const [dropdownLangActiveClass, setDropdownLangActiveClass] = useState("");

  var slug = props.slug;

  if (!(props.langKey === "en" || props.langKey === undefined)) {
    slug = `${slug === undefined ? "" : (slug).substring(3)}`
  }

  function toggleHamburger() {
    setNavBarActiveClass(navBarActiveClass === "" ? 'is-active' : "");
  }

  function toggleDropdownInfo(props) {
    if (props === 'SECTIONS') {
      setDropdownSectionsActiveClass(dropdownSectionsActiveClass === "" ? 'is-active' : "");
    } else {
      setDropdownInfoActiveClass(dropdownInfoActiveClass === "" ? 'is-active' : "");
    }
  }

  function toggleDropdownLang() {
    setDropdownLangActiveClass(dropdownLangActiveClass === "" ? 'is-active' : "");
  }

  const [node] = useNavbarItems()
  const [nodeLang] = useLanguageList()
  const frontmatter = node.node.frontmatter
  const languages = nodeLang.node.frontmatter.languages
  const isFixedTop = frontmatter.isFixedTop ? 'is-fixed-top' : ''
  const logo = frontmatter.logo
  const logoIsBold = frontmatter.logo.isBold ? 'has-text-weight-bold' : ''
  const languageSelector = frontmatter.languageSelector
  var itemAlign = ''

  switch(frontmatter.item.align) {
    case 'left':
      itemAlign = 'navbar-start'
      break
    case 'right':
      itemAlign = 'navbar-end'
      break
  }

  const itemFontSizeWidescreen = frontmatter.item.fontSizeWidescreen + '-widescreen'
  const itemFontSizeTablet = frontmatter.item.fontSizeTablet + '-tablet'
  const itemFontSizeMobile = frontmatter.item.fontSizeMobile + '-mobile'
  const itemIsBold = frontmatter.item.isBold ? 'has-text-weight-bold' : ''
  const { tagList: tags } = useFeaturedTags()

  return (
    <nav
      className={`navbar ${isFixedTop}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link
            className="navbar-item" title="Logo"
            to={
              `${props.langKey === "en" || props.langKey === undefined
                ? ""
                : props.langKey
              }/`
            }
          >
            {logo.useImage &&
              <img
                src={props.logo}
                alt={logo.siteName}
              />
            }
            {logo.useSiteName &&
              <p
                className={`${logo.fontSize} ${logoIsBold}`}
                style={{
                  letterSpacing: `${logo.letterSpacing}`,
                }}
              >
                {logo.siteName}
              </p>
            }
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
            onKeyPress={toggleHamburger}
            role="menuitem"
            tabIndex="0"
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${navBarActiveClass}`}
        >
          <div className={`${itemAlign} has-text-centered`}>
            {frontmatter.items.map(item =>
              {return item.link != null ? (
                <>
                  {item.translations
                    .filter(translation => translation.langKey === props.langKey)
                    .map(translation => (
                      <Link
                        className={`navbar-item ${itemFontSizeWidescreen} ${itemFontSizeTablet} ${itemFontSizeMobile} ${itemIsBold}`}
                        to={
                          `${props.langKey === "en" || props.langKey === undefined
                            ? ""
                            : props.langKey
                          }${item.link}`
                        }
                      >
                        {translation.name}
                      </Link>
                    ))
                  }
                </>
              ) : (
                <div className={`navbar-item has-dropdown is-hoverable ${itemIsBold}`}>
                  <>
                    {item.translations
                      .filter(translation => translation.langKey === props.langKey)
                      .map(translation => (
                        <span
                          className={`navbar-link ${itemFontSizeWidescreen} ${itemFontSizeTablet} ${itemFontSizeMobile}`}
                          onClick={() => toggleDropdownInfo(translation.name)}
                          onKeyPress={toggleDropdownInfo}
                          role="menuitem"
                          tabIndex="0"
                        >
                          {translation.name}
                        </span>
                      ))
                    }
                  </>
                  <>
                    {item.subItems != null &&
                      <>
                        {!item.tags ? (
                          <div className={`navbar-dropdown ${dropdownInfoActiveClass}`}>
                            {item.subItems.map(subItem =>
                              <>
                                {subItem.translations
                                  .filter(translation => translation.langKey === props.langKey)
                                  .map(translation => (
                                    <Link
                                      className="navbar-item"
                                      to={
                                        `${props.langKey === "en" || props.langKey === undefined
                                          ? ""
                                          : props.langKey
                                        }${subItem.link}`
                                      }
                                    >
                                      {translation.name}
                                    </Link>
                                  ))
                                }
                              </>
                            )}
                          </div>
                        ) : (
                          <div className={`navbar-dropdown ${dropdownSectionsActiveClass}`}>
                            {tags
                              .filter(tag => tag.featured)
                              .map(tag =>
                                <div>
                                  {tag.translations && tag.translations
                                    .filter(translation => translation.langKey === props.langKey)
                                    .map(translation =>
                                      <Link
                                        className="navbar-item"
                                        to={
                                          `${props.langKey === "en" || props.langKey === undefined
                                            ? ""
                                            : props.langKey
                                          }${tag.tag}`
                                        }
                                      >
                                        {startCase(translation.tagDisplay)}
                                      </Link>
                                    )
                                  }
                                </div>
                              )
                            }
                            {item.subItems.map(subItem =>
                              <>
                                {subItem.translations
                                  .filter(translation => translation.langKey === props.langKey)
                                  .map(translation => (
                                    <Link
                                      className="navbar-item"
                                      to={
                                        `${props.langKey === "en" || props.langKey === undefined
                                          ? ""
                                          : props.langKey
                                        }${subItem.link}`
                                      }
                                    >
                                      {translation.name}
                                    </Link>
                                  ))
                                }
                              </>
                            )}
                          </div>
                        )}
                      </>
                    }
                  </>
                </div>
              )}
            )}
          </div>
          {languageSelector.use &&
            <div className="navbar-item has-dropdown is-hoverable">
              <span
                className="navbar-link"
                onClick={() => toggleDropdownLang()}
                onKeyPress={toggleDropdownLang}
                role="menuitem"
                tabIndex="0"
              >
                <img src={langSelector} alt="Language Selector" style={{ width: '20px', height: '20px'}} />
              </span>
              <div className={`navbar-dropdown ${dropdownLangActiveClass} has-text-centered`}>
                {languages.map(language => (
                  <Link
                    className={`navbar-item ${itemFontSizeWidescreen} ${itemFontSizeTablet} ${itemFontSizeMobile}`}
                    to={
                      `/` + `${language.langKey === 'en' ? '' : language.langKey + '/'}` + `${slug}`
                    }
                  >
                    {language.langName}
                  </Link>
                ))}
              </div>
            </div>
          }
        </div>
      </div>
    </nav>
  )
}

export default Navbar;