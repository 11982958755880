import React from 'react';
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import { useSiteMetadata } from "../../src/hooks/use-site-metadata"
export default function SEO({title, description, name, type, image, twitterImage}) {
    const {  logo, siteUrl } = useSiteMetadata();
    return (
        <Helmet>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={description} />

            <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${withPrefix("/")}files/favicon/apple-touch-icon.png`}
            />
            <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${withPrefix("/")}files/favicon/favicon-32x32.png`}
            />
            <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${withPrefix("/")}files/favicon/favicon-16x16.png`}
            />
            {/* <link
            rel="manifest"
            href={`${withPrefix("/")}files/favicon/site.webmanifest`}
            /> */}
            <link
            rel="mask-icon"
            href={`${withPrefix("/")}files/favicon/safari-pinned-tab.svg`}
            color="#f8971d"
            />
            <link
            rel="shortcut icon"
            href={`${withPrefix("/")}files/favicon/favicon.ico`}
            />
            <meta
            name="apple-mobile-web-app-title"
            content={description}
            />
            <meta name="application-name" content={description} />
            <meta name="msapplication-TileColor" content="#f8971d" />
            <meta
            name="msapplication-config"
            content={`${withPrefix("/")}files/favicon/browserconfig.xml`}
            />
            <meta name="theme-color" content="#ffffff" />
            <link
            rel="manifest"
            href={`${withPrefix("/")}files/favicon/manifest.json`}
            />
            
            <meta name="og:image" content={`${withPrefix("/")}`+image}/>

            <meta name="twitter:card" content="summary_large_image" />
            {/* <meta name="twitter:site" content="@digitalocean" /> */}
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={siteUrl+`${withPrefix("/")}`+twitterImage} />
        </Helmet>

    )}