import { useStaticQuery, graphql } from "gatsby";

export const useLatestBlog = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: frontmatter___createDate, order: DESC }
          filter: { frontmatter: { createDate: { ne: null } } }
          limit: 1
        ) {
          edges {
            node {
				fields {
					pathSlug
				}
				frontmatter {
					content
					description
					title
					updateDate
					createDate
				}
			}
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges;
};
