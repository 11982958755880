import React from "react";
import OneColumnContent from "../columns/OneColumnContent";
import TwoColumnContent from "../columns/TwoColumnContent";
import ThreeColumnContent from "../columns/ThreeColumnContent";

const Columns = props => {

  const whiteOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the opacity as needed
    zIndex: 1,
  };

  const backgroundImagePositionStyle = {
    position: 'relative',
    zIndex: 2,
    color: 'black', // Adjust as needed
    paddingTop: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem'
};

	return (
    <>

      {/* One Column w/ Image Background*/}
      {props.content.columnCount === 'one' && props.content.backgroundImage &&
      // Background Image Implemented only for Single column
      <div style={{  position: 'relative',
        width: '100%',
        height: '100%', // Adjust as needed
        backgroundImage: `url(${props.content.backgroundImage.childImageSharp.fluid.src})`, // Replace with your image URL
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',}}>
        {/* White Overlay to make the background image lighter */}
        <div style={ whiteOverlayStyle}></div>
        <div style={ backgroundImagePositionStyle}>
          <OneColumnContent
            columns={props.content.columns}
            langKey={props.langKey}
            reusable={props.reusable}
          />
        </div>
      </div>
      }
      {/* One Column */}
      {props.content.columnCount === 'one' && !props.content.backgroundImage &&
        <OneColumnContent
          columns={props.content.columns}
          langKey={props.langKey}
          reusable={props.reusable}
        />
      }

      {/* Two Columns */}
      {props.content.columnCount === 'two' &&
        <TwoColumnContent
          columns={props.content.columns}
          langKey={props.langKey}
          reusable={props.reusable}
        />
      }

      {/* Three Columns */}
      {props.content.columnCount === 'three' &&
        <ThreeColumnContent
          columns={props.content.columns}
          langKey={props.langKey}
          reusable={props.reusable}
        />
      }

    </>
	);
};

export default Columns;
