import React from "react";
import PropTypes from 'prop-types'
import { useLatestBlog } from "../../hooks/use-latest-blog";

const Sidebar = (  {contents, relatedArticles}) => {

const getLatestBlog = useLatestBlog();
const title = getLatestBlog[0].node.frontmatter.title;
const pathSlug = getLatestBlog[0].node.fields.pathSlug;
const description = getLatestBlog[0].node.frontmatter.description;
const createDate = getLatestBlog[0].node.frontmatter.createDate;

  return (
    <div className="column is-3 is-one-fifths" style={{marginBottom: "2rem"}}>
      <div className="sidebar">
        <div className="card">
          <div className="card-content">
            <div className="media">
              <div>
                <p className="title is-8">What's new</p>
                <p className="subtitle is-12">
                  <a href={pathSlug}>
                    {title}
                  </a>
                </p>
              </div>
            </div>

            <div >
              {description ? 
                <div>
                  {description}
                  <br />
                  <br />
                </div>: null
              }
              
              <time>Created: {createDate}</time>
            </div>
          </div>
        </div>
        {relatedArticles ?  <div className="card" style={{ marginTop: "2px" }}>
            <div className="card-content">
              <div className="media">
                <div>
                  <p className="title is-9">Check out these related articles</p>
                </div>
              </div>
            </div>
          </div> : null
        }
       

        {
          relatedArticles ? relatedArticles.map(item=>{
            return (
              <div className="card">
                <div></div>
                <div className="card-content">
                  <div className="media">
                    <div >
                      <p className="title is-9">
                        <a href={item.path}>
                          {item.title}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div>
                    {item.description}
                  </div>
                </div>
              </div>
          )
        }) : null}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  contents: PropTypes.node.isRequired,
}





export default Sidebar;
