import React from 'react'
import remark from "remark";
import parse from "remark-parse";
import remarkToreact from 'remark-react';
import remarkAttr from 'remark-attr';
import { SocialIcon } from 'react-social-icons';
import { useFooter } from '../../../src/hooks/use-footer'

function Footer() {
  const [node] = useFooter()
  const content = node.node.frontmatter.content

  return (
    <footer className="footer">
      <div className="content has-text-centered">
        {remark().use(parse).use(remarkAttr).use(remarkToreact).processSync(content).contents}
        <div>Follow Us</div>
          <SocialIcon style={{margin: "2px", backgroundColor:"white", borderRadius:"5rem"}} url="https://www.facebook.com/profile.php?id=100057451509287" />
          <SocialIcon style={{margin: "2px", backgroundColor:"white", borderRadius:"5rem"}}  url="https://www.youtube.com/@MaintenanceCanada/" />
      </div>
    </footer>
  )
}

export default Footer
